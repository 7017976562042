import { memo, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { ConfigContext } from '../../context/configContext';
import { ComponentType } from "../../themes/models";
import { format } from "../../utils";
import { ErrorBoundary } from "../ErrorBoundary";

interface IOption {
    value: string | number | undefined;
    text: string | undefined;
}

interface IDefaultValue {
    defaultValue?: string | number | undefined;
}

interface ISelectBaseProps {
    id: string;
    label: string;
    helpText?: string;
}

interface IUseSelectQueryProps extends ISelectBaseProps {
    query: (args: string[]) => Promise<IOption[] | undefined | null>;
    queryParams?: string[];
}

interface ISelectProps extends ISelectBaseProps {
    options: IOption[];
    value?: string | number | undefined;
    onChange?: (value: string | number) => void;
    status?: string;
}

export const Select = memo(({ id, options, label, value, helpText, onChange, status }: ISelectProps) => {
    const { t } = useTranslation();
    const { theme } = useContext(ConfigContext);
    const inputType = ComponentType.Select;
    const inputId = `${id}_select`, idHelp = `${id}_help`;
    if (status) value = '';
    return (<ErrorBoundary errorMessage={format(t('error.fieldError'), label)}><div {...theme.getElementAttributes<'div'>(inputType)} id={id}>
        <label {...theme.getElementAttributes(inputType, 'label')} htmlFor={inputId}>{label}</label>
        <select {...theme.getElementAttributes(inputType, 'select')}
            id={inputId} defaultValue={value} aria-describedby={helpText ? idHelp : undefined}
            onChange={(e) => onChange && onChange(e.target.value)}>
            {status ? <option disabled value={value}>{status}</option> : options.map((m, i) => <option key={i} value={m.value}>{m.text}</option>)}
        </select>
        {helpText && <div {...theme.getElementAttributes(inputType, 'div', '-help')} id={idHelp}>
            {helpText}
        </div>}
    </div></ErrorBoundary>);
});

export const useSelect = ({ id, label, helpText, defaultValue }: ISelectBaseProps & IDefaultValue) => {
    const [value, setValue] = useState<string | number | undefined>(defaultValue);
    const [options, setOptions] = useState<IOption[]>([]);
    useEffect(() => {
        if (!value && options && options.length) {
            setValue(options[0].value);
        }
    }, [value, options]);
    const Component = useMemo(() => ({ value, status }: { value: string | number | undefined, status?: string | undefined }) =>
        <Select id={id} label={label} helpText={helpText} value={value} options={options} onChange={setValue} status={status} />, [id, label, helpText, options]);
    return { Component, value, setValue, setOptions };
}

export function useSelectWithQuery({ id, label, helpText, defaultValue, query, queryParams }: IUseSelectQueryProps & IDefaultValue) {
    const { t } = useTranslation();
    const { Component: SelectComponent, value, setOptions, setValue } = useSelect({ id, label, helpText, defaultValue });
    const { data, error } = useSWR(['useSelectWithQuery.' + id].concat(queryParams || []), query, { revalidateOnFocus: false });
    useEffect(() => setOptions(data || []), [setOptions, data]);
    const Component = useMemo(() => ({ value }: { value: string | number | undefined }) =>
        <SelectComponent value={value} status={error ? t('api.error') : !data ? t('api.loading') : undefined} />, [SelectComponent, data, error, t]);
    return { Component, value, setValue, setOptions };
}