import { memo } from "react";
import { useTranslation } from "react-i18next";
import { api } from "../../api";
import { getClassName, sort, sortWithNumber } from "../../utils";
import { CalculateResult, useInput, useSelectWithQuery } from "..";

export const CalculateForm = memo(({ prefixId }: { prefixId: string }) => {
    const { t } = useTranslation();
    const { Component: OldUValueSelect, value: oldUValue } = useSelectWithQuery({ id: `${prefixId}_oldUValue`, label: t('fields.oldUValue.label'), helpText: t('fields.oldUValue.help'), query: async () => (await api.home.oldUValue()).uValues?.map(v => ({ value: v.value, text: v.text || '' })) });
    const { Component: NewUValueSelect, value: newUValue } = useSelectWithQuery({ id: `${prefixId}_newUValue`, label: t('fields.newUValue.label'), helpText: t('fields.newUValue.help'), query: async () => (await api.home.newUValue()).uValues?.map(v => ({ value: v.value, text: v.text || '' })) });
    const { Component: QuantityInput, value: quantity } = useInput({ id: `${prefixId}_quantity`, label: t('fields.quantity.label'), helpText: t('fields.quantity.help'), defaultValue: "0" });
    const { Component: EnergyAreaSelect, value: energyArea } = useSelectWithQuery({ id: `${prefixId}_energyArea`, label: t('fields.energyArea.label'), helpText: t('fields.energyArea.help'), query: async () => sort((await api.home.energyArea()).energyAreas, v => v.text) });
    const { Component: EnergyTypeSelect, value: energyType } = useSelectWithQuery({ id: `${prefixId}_energyType`, label: t('fields.energyType.label'), helpText: t('fields.energyType.help'), query: async () => (await api.home.energyType()).energyTypes?.map(v => ({ value: v.value || '', text: v.text || '' })) });
    const { Component: EnergyCostSelect, value: energyCost } = useSelectWithQuery({ id: `${prefixId}_energyCost`, label: t('fields.energyCost.label'), helpText: t('fields.energyCost.help'), defaultValue: '1.3', query: async ([_, et]) => et ? sortWithNumber((await api.home.energyCost(et)).energyCosts, v => v.text) : [], queryParams: [ energyType?.toString() || '' ] });

    return (<div className={getClassName(CalculateForm.name)}>
        <OldUValueSelect value={oldUValue} />
        <NewUValueSelect value={newUValue} />
        <QuantityInput value={quantity} type='number' min={0} />
        <EnergyAreaSelect value={energyArea} />
        <EnergyTypeSelect value={energyType}  />
        <EnergyCostSelect value={energyCost} />
        <CalculateResult id={`${prefixId}_result`} {...{oldUValue, newUValue, quantity, energyType, energyArea, energyCost }} />
    </div>);
});