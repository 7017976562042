import { useId } from "react";
import { ComponentType } from "../../themes/models";
import { getComponentClassName } from "../../utils";
import { CalculateForm, ErrorBoundary } from "..";
import { useTranslation } from "react-i18next";

export const appClass = getComponentClassName(ComponentType[ComponentType.App]);

export const App = () => {
  const id = useId();
  const { t } = useTranslation();
  return (
    <div id={id} className={appClass} data-testid="energy-calculator-app">
      <ErrorBoundary errorMessage={t('error.unknownError')}>
        <CalculateForm prefixId={id} />
      </ErrorBoundary>
    </div>
  );
};