import { InputHTMLAttributes, memo, useContext, useMemo, useState } from "react";
import { ConfigContext } from '../../context/configContext';
import { ComponentType } from "../../themes/models";

interface IInputBaseProps {
    id: string;
    label: string;
    helpText?: string;
    defaultValue?: string;
}

interface IUseInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'id' | 'defaultValue'> {

}

interface IInputProps extends IInputBaseProps, IUseInputProps {
    onChange?: (value: string) => void;
}

export const Input = memo(({ id, label, value, helpText, onChange, ...rest }: IInputProps) => {
    const { theme } = useContext(ConfigContext);
    const inputType = ComponentType.Input;
    const inputId = `${id}_input`, idHelp = `${id}_help`;
    return (<div {...theme.getElementAttributes<'div'>(inputType)} id={id}>
        <label {...theme.getElementAttributes(inputType, 'label')} htmlFor={inputId}>{label}</label>
        <input {...theme.getElementAttributes(inputType, 'input')} {...rest}
            id={inputId} value={value} aria-describedby={helpText ? idHelp : undefined}
            onChange={(e) => onChange && onChange(e.target.value)} />
        {helpText && <div {...theme.getElementAttributes(inputType, 'div', '-help')} id={idHelp}>
            {helpText}
        </div>}
    </div>);
});

export const useInput = ({ id, label, helpText, defaultValue }: IInputBaseProps) => {
    const [value, setValue] = useState<string>(defaultValue || '');
    const Component = useMemo(() => ({ value, ...rest } : IUseInputProps) => 
        <Input {...rest} id={id} label={label} helpText={helpText} value={value} onChange={setValue} />, [id, label, helpText]);
    return { Component, value, setValue };
}