/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnergyAreaResponse } from "../models/EnergyAreaResponse";
import type { EnergyCostResponse } from "../models/EnergyCostResponse";
import type { EnergyTypeResponse } from "../models/EnergyTypeResponse";
import type { UValueResponse } from "../models/UValueResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class HomeService {
  /**
   * List all EnergyAreas
   * @returns EnergyAreaResponse Success
   * @throws ApiError
   */
  public static energyArea(): CancelablePromise<EnergyAreaResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/v1/Home/EnergyArea",
    });
  }

  /**
   * List all EnergyTypes and costs
   * @returns EnergyCostResponse Success
   * @throws ApiError
   */
  public static energyTypesCosts(): CancelablePromise<
    Array<EnergyCostResponse>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/v1/Home/EnergyTypesCosts",
    });
  }

  /**
   * List EnergyType
   * @returns EnergyTypeResponse Success
   * @throws ApiError
   */
  public static energyType(): CancelablePromise<EnergyTypeResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/v1/Home/EnergyType",
    });
  }

  /**
   * List costs for given EnergyType
   * @param type
   * @returns EnergyCostResponse Success
   * @throws ApiError
   */
  public static energyCost(
    type?: string
  ): CancelablePromise<EnergyCostResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/v1/Home/EnergyCost",
      query: {
        type: type,
      },
    });
  }

  /**
   * @returns UValueResponse Success
   * @throws ApiError
   */
  public static oldUValue(): CancelablePromise<UValueResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/v1/Home/OldUValue",
    });
  }

  /**
   * @returns UValueResponse Success
   * @throws ApiError
   */
  public static newUValue(): CancelablePromise<UValueResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/v1/Home/NewUValue",
    });
  }
}
