import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { LangParams } from "../utils";

import resEn from "./translation.en.json";
import resSv from "./translation.sv.json";

export const defaultNS = "translation"
export const resources = {
  en: {
    [defaultNS]:  resEn
  },
  sv: {
    [defaultNS]:  resSv
  }
} as const;

export function initI18N(params: LangParams)
{
    const moduleConfig = i18n
        .use(initReactI18next);
    const initConfig: InitOptions = {
        resources,
        defaultNS,
        interpolation: {
          escapeValue: false
        }
    };
    if(!params.lang)
    {
        moduleConfig.use(LanguageDetector);
    }
    else {
        initConfig.lng = params.lang; 
    }

    moduleConfig.init(initConfig);
}
