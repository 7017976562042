import { memo, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "../../api";
import { ConfigContext } from '../../context/configContext';
import { ComponentType } from "../../themes/models";

interface ICalculateResultProps {
    id: string;
    oldUValue: string | number | undefined;
    newUValue: string | number | undefined;
    quantity: string | number | undefined;
    energyArea: string | number | undefined;
    energyType: string | number | undefined;
    energyCost: string | number | undefined;
}

export const CalculateResult = memo(({ id, oldUValue, newUValue, quantity, energyArea, energyType, energyCost }: ICalculateResultProps) => {
    const { t } = useTranslation()
    const { theme } = useContext(ConfigContext);
    const [kr, setKr] = useState(0);
    const [kWh, setkWh] = useState(0);
    const [status, setStatus] = useState('');

    const resetResult = () => {
        setKr(0);
        setkWh(0);
    }

    useEffect(() => {
        if (oldUValue && newUValue && quantity && energyArea && energyType && energyCost) {
            const toFloat = (s: string) => parseFloat(s.replace(",", "."));
            const q = parseInt(quantity?.toString()), ou = toFloat(oldUValue?.toString()), no = toFloat(newUValue?.toString());
            if (q > 0 && ou > 0 && no > 0) {
                api.calculator.calculate({ oldUVal: ou, newUVal: no, itemQuantity: q, energyArea: parseFloat(energyArea?.toString()), energyCost: parseFloat(energyCost?.toString()) })
                    .catch(() => setStatus(t('api.error')))
                    .then(v => {
                        setKr(v?.kr || 0);
                        setkWh(v?.kWh || 0);
                    });
            }
            else {
                resetResult();
            }
        }
        else {
            resetResult();
        }
    }, [oldUValue, newUValue, quantity, energyArea, energyType, energyCost, t]);

    const inputType = ComponentType.CalculateResult;
    const textId = `${id}_text`;
    return (<div {...theme.getElementAttributes<'div'>(inputType)} id={id}>
        <label {...theme.getElementAttributes(inputType, 'label')} htmlFor={textId}>{t('calculate.perYearSave')}</label>
        <div {...theme.getElementAttributes(inputType, 'div')} id={textId}>
            {status ? status : <>
                <span {...theme.getElementAttributes(inputType, 'span', kr > 0 ? '-success' : '')}>{kr.toFixed(0)} kr</span>
                {' ' + t('calculate.byReducingYour') + ' '}
                <span {...theme.getElementAttributes(inputType, 'span', kWh > 0 ? '-success' : '')}>{kWh.toFixed(0)} kWh</span>
            </>}
        </div>
    </div>);
});