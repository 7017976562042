// @ts-ignore
import { default as BootstrapStyle } from './index.scss';
import { ColorParams, getComponentClassName } from '../../utils';
import { ComponentType, ITheme } from '../models';
import { appClass } from '../../components';

export class BootstrapTheme implements ITheme {
    getStyles(): string | undefined {
        return BootstrapStyle.toString();
    }

    getStyleNode(config: ColorParams): HTMLStyleElement | undefined {
        var styles = this.getStyles();
        if (styles) {
            import(/* webpackChunkName: "bootstrap" */'bootstrap');

            const styleNode = document.createElement('style');
            styleNode.innerHTML = styles + "\n\n" +
                `.${appClass} {
                --test-var: ${config.color1 || 'green'};
            }`;
            return styleNode;
        }
    }

    getElementAttributes<K extends keyof HTMLElementTagNameMap>(component: ComponentType, innerType?: K, innerSuffixIdentifier?: string): React.HTMLAttributes<HTMLElementTagNameMap[K]> | undefined {
        const innerIdentifier = `${innerType || ''}${innerSuffixIdentifier || ''}`;
        const componentClassName = getComponentClassName(ComponentType[component]);
        switch (component) {
            case ComponentType.Select:
            case ComponentType.Input:
                switch (innerIdentifier) {
                    case '': return { className: `${componentClassName} mb-3` };
                }
                break;
            case ComponentType.CalculateResult:
                switch (innerIdentifier) {
                    case '': return { className: `${componentClassName} mb-3` };
                    case 'span-success': return { className: 'badge text-bg-success' };
                    case 'span': return { className: 'badge text-bg-light' };
                    case 'div': return { className: 'fs-4' };
                }
                break;
        }
        switch (innerIdentifier) {
            case '': return { className: componentClassName };
            case 'div-help': return { className: `form-text` };
            case 'label': return { className: 'form-label' };
            case 'select': return { className: 'form-select' };
            case 'input': return { className: 'form-control' };
        }
        return {};
    }
}