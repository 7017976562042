import { Component, ErrorInfo, ReactNode } from "react";

export class ErrorBoundary extends Component<{ errorMessage?: string, children?: ReactNode }, { hasError: boolean }> {
    public state = {
        hasError: false
    };

    public static getDerivedStateFromError(_: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return <p>{this.props.errorMessage ?? 'Unknown error'}</p>;
        }

        return this.props.children;
    }
}