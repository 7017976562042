const startsWithNumRegex = /^(\d+(?:[:.,]\d+)?)(.+)/;

const defaultSort = (a: any, b: any) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
}

export function sortWithNumber<T>(array: T[] | undefined | null, selector: (r: T) => any = r => r): any[] {
    return array?.sort((v1, v2) => {
        const a = selector(v1), b = selector(v2);
        if (typeof a === 'string' && typeof b === 'string') {
            const matchA = a.match(startsWithNumRegex);
            const matchB = b.match(startsWithNumRegex);
            if(matchA)
            {
                if (matchB) {
                    const floatA = parseFloat(matchA[1].replace(/[:,]/g, '.'));
                    const floatB = parseFloat(matchB[1].replace(/[:,]/g, '.'));
                    if(floatA !== floatB) {
                        return floatA < floatB ? -1 : 1;
                    }
                } else {
                    return 1;
                }

            } else if (matchB) {
                return -1;
            }
        }
        return defaultSort(a, b);
    }) || [];
}

export function sort<T>(array: T[] | undefined | null, selector: (r: T) => any = r => r): any[] {
    return array?.sort((v1, v2) => defaultSort(selector(v1), selector(v2))) || [];
}