/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnergyConsumptionAreaRequest } from "../models/EnergyConsumptionAreaRequest";
import type { EnergyConsumptionRequest } from "../models/EnergyConsumptionRequest";
import type { EnergyConsumptionResponse } from "../models/EnergyConsumptionResponse";
import type { EnergyConsumtionOrderRequest } from "../models/EnergyConsumtionOrderRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class CalculatorService {
  /**
   * @param requestBody
   * @returns EnergyConsumptionResponse Success
   * @throws ApiError
   */
  public static calculate(
    requestBody?: EnergyConsumptionRequest
  ): CancelablePromise<EnergyConsumptionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/v1/Calculator/Calculate",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns EnergyConsumptionResponse Success
   * @throws ApiError
   */
  public static calculateOnArea(
    requestBody?: EnergyConsumptionAreaRequest
  ): CancelablePromise<EnergyConsumptionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/v1/Calculator/CalculateOnArea",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns EnergyConsumptionResponse Success
   * @throws ApiError
   */
  public static calculateOrder(
    requestBody?: EnergyConsumtionOrderRequest
  ): CancelablePromise<EnergyConsumptionResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/v1/Calculator/CalculateOrder",
      body: requestBody,
      mediaType: "application/json",
    });
  }
}
