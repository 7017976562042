import { BootstrapTheme } from "./bootstrap";
import { CssSource, ITheme, Themes } from "./models";
import { NoneTheme } from "./none";

const availableThemes = new Map<Themes | undefined, ITheme>();
availableThemes.set(Themes.None, new NoneTheme());
availableThemes.set(Themes.Bootstrap, new BootstrapTheme());

export { 
    availableThemes,
    CssSource,
    Themes
}