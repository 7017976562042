import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './components';
import { ConfigProvider } from './context/configContext';
import { initI18N } from './i18n';
import reportWebVitals from './reportWebVitals';
import { availableThemes, CssSource } from './themes';
import { NoneTheme } from './themes/none';
import { containerId, getConfigParams } from './utils';

const config = getConfigParams();
const theme = availableThemes.get(config.theme) ?? new NoneTheme();
const root = document.getElementById(containerId) as HTMLElement;
let renderRoot = root;
if(config.cssSource === CssSource.Within)
{
  const shadow = root.attachShadow({ mode: 'open' });
  const innerRoot = document.createElement('div');
  const styleNode = theme?.getStyleNode(config);
  if(styleNode) {
    shadow.appendChild(styleNode);
  }
  shadow.appendChild(innerRoot);
  renderRoot = innerRoot;
}

initI18N(config);

ReactDOM.createRoot(renderRoot).render(
  <React.StrictMode>
    <ConfigProvider value={{ config, theme }}>
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
