import { getComponentClassName } from '../../utils';
import { ColorParams } from '../../utils';
import { ComponentType, ITheme } from '../models';

export class NoneTheme implements ITheme {
    getStyles(): string | undefined {
        return undefined;
    }

    getStyleNode(_: ColorParams): HTMLStyleElement | undefined {
        return undefined;
    } 

    getElementAttributes<K extends keyof HTMLElementTagNameMap>(component: ComponentType, innerType?: K, innerSuffixIdentifier?: string): React.HTMLAttributes<HTMLElementTagNameMap[K]> | undefined  {
        const innerIdentifier = `${innerType || ''}${innerSuffixIdentifier || ''}`;
        if(!innerIdentifier) {
            return { className: getComponentClassName(ComponentType[component]) };
        }
        return {};
    }
}