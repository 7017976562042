export const nameof = <T>(name: keyof T) => name;

export function convertUpperToHyphenLower(value: string | undefined): string | undefined {
    return value?.replace(/([a-z])([A-Z]+)/g, (m) => m[0] + "-" + m[1]).toLowerCase();
}

export function getComponentClassName<T>(name: keyof T) {
    return getClassName(nameof<T>(name)?.toString());
}

export function getClassName(name: string) {
    return 'energy-calculator-' + convertUpperToHyphenLower(name);
}

export function format(value: string, ...args: string[])
{
    return value.replace(/{(\d+)}/g, (match, number) => number < args.length && typeof args[number] != 'undefined' ? args[number] : match);
}