import { CssSource, Themes } from "../themes";
import { convertUpperToHyphenLower } from "./stringutils";

export const containerId = 'rootEnergyCalculator';

export interface LangParams
{
    lang?: string | null
}

export interface ColorParams
{
    color1?: string | null,
    color2?: string | null,
    color3?: string | null
}

export interface ConfigParams extends ColorParams, LangParams
{
    theme?: Themes,
    cssSource?: CssSource,
}

export function getConfigParams(): ConfigParams
{
    const container = document.getElementById(containerId);
    const params = new URLSearchParams(window.location.search);
    const theme = getSetConfigParam(container, params, 'theme') as keyof typeof Themes;
    const config = {
        lang: getSetConfigParam(container, params, 'lang'),
        theme: theme ? Themes[theme] : Themes.Bootstrap,
        cssSource: CssSource[getSetConfigParam(container, params, 'cssSource') as keyof typeof CssSource] || CssSource.Within,
        color1: getSetConfigParam(container, params, 'color1'),
        color2: getSetConfigParam(container, params, 'color2'),
        color3: getSetConfigParam(container, params, 'color3'),
    };
    return config;
}

export function getSetConfigParam<K extends keyof ConfigParams>(container: HTMLElement | null, params: URLSearchParams, param: K): string | null {
    const dataAttributeName = 'data-' + convertUpperToHyphenLower(param);
    return container?.getAttribute(dataAttributeName) || params.get(param);
}